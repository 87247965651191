<template>
  <div class="approval-history">
    <vue-timeline-update
      v-for="history in approvalHistory"
      :key="history.id"
      :title="history.position"
      :date="new Date(history.updatedAt)"
      :category="history.status"
      :description="formatDescription(history.fullName, history.approvalReason)"
      icon="code"
      :color="determineColorByStatus(history.status)"
    />
  </div>
</template>

<script>
export default {
  props: ['approvalHistory'],
  methods: {
    formatDescription(name, approvalReason) {
      let fullName = name
      let reason = approvalReason
      if (!reason) {
        return fullName
      }
      return reason + ' -- by: ' + fullName
    },
    determineColorByStatus(status) {
      if (status === 'approved') {
        return 'green'
      }
      if (status === 'waiting') {
        return 'blue'
      }

      if (status === 'declined') {
        return 'red'
      }
      return 'purple'
    },
  },
}
</script>
